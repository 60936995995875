import { useState } from "react";
import useAPI from "../utils/useAPI";
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItem, ListItemText, TextField } from "@material-ui/core";
import PromiseButton from "../components/PromiseButton";
import { Done } from "@material-ui/icons";
import { useAuth } from "../contexts/Auth";
import getMonth from "../utils/getMonth";

interface Course {
    alternateLink?: any;
    courseId: string;
    courseState: string;
    creationTime: number;
    currentMonth: number;
    descriptionHeading?: any;
    enabled: boolean;
    googleClassroomId?: any;
    name: string;
    ownerId?: string;
    studentCount: number;
    teamCount?: any;
    updateTime: number;
    joinCode: string;
}

export default function JoinTeam({ open, retry }: { open: boolean, retry: () => Promise<any> }) {
    const [res, refresh] = useAPI<Course[]>('user/me/enrolled')
    const [, callAPI] = useAuth()
    const [joinCode, setJoinCode] = useState("")
    const courses = res?.json

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>
                Join a Team
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Your teacher needs to add you to a team and create a store.
                </DialogContentText>

                {courses !== undefined && (courses.length === 0 ? (
                    <DialogContentText>
                        You'll need to join their Google Classroom first, or enter the Retail Simulation join code (different to Google Classroom).
                    </DialogContentText>
                ) : (
                    <>
                        <DialogContentText>
                            Double check that {(res?.json?.length || 0) > 1 ? "these are your courses" : "this is your course"}:
                        </DialogContentText>
                        <List style={{ margin: -16, marginBottom: 0 }} disablePadding>
                            {(courses || []).map(c => (
                                <ListItem key={c.courseId}>
                                    <ListItemText primary={c.name} secondary={c?.descriptionHeading === c?.name || !c?.descriptionHeading ? [
                                        c.studentCount && c.studentCount + " students",
                                        c.teamCount && c.teamCount + " teams",
                                        c.currentMonth && getMonth(c.currentMonth)
                                    ].filter(Boolean).join(", ")
                                        : c?.descriptionHeading} />
                                </ListItem>
                            ))}
                        </List>
                        <DialogContentText>
                            Not seeing your course? Join it on Google Classroom, or enter the Retail Simulation join code (different to Google Classroom):
                        </DialogContentText>
                    </>
                ))}

                <Box style={{ display: "flex" }}>
                    <TextField label="Join Code" variant="outlined" fullWidth value={joinCode} onChange={e => setJoinCode(e.target.value)} />
                    <IconButton style={{ marginRight: -8 }} onClick={async () => {
                        await callAPI(`course/join/${joinCode}`, "POST")
                        refresh()
                    }}>
                        <Done />
                    </IconButton>
                </Box>

            </DialogContent>
            <DialogActions>
                <PromiseButton
                    onClick={retry}
                // disabled={!courses?.length}
                >
                    Retry
                </PromiseButton>
            </DialogActions>
        </Dialog>

    )
}