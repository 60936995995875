import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./Auth";

const demoUser: User = {
  email: "",
  familyName: "",
  givenName: "",
  googleId: "",
  name: "",
  picture: "",
  userId: ""
}

const UserContext = createContext<[
  User,
  () => Promise<User>,
  (user: User) => void
]>([
  demoUser,
  () => { throw new Error("No user provider") },
  () => { throw new Error("No user provider") }
])

export default UserContext;

export function UserProvider({ children }: any) {
  const [user, setUser] = useState<User>(() => localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : demoUser);
  const [auth, callAPI] = useAuth()

  async function reloadUser() {
    if (auth) {
      const { status, success, json } = await callAPI<User>("user/me");
      if (success) {
        setUser(json);
      }
    }

    return user!;
  }

  useEffect(() => {
    if (auth) {
      reloadUser();
    } else {
      setUser(demoUser);
    }
  }, [auth]);

  useEffect(() => {
    if (user) {
      console.log("SETTING USER", user)
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      console.log("REMOVING USER")
      localStorage.removeItem("user");
    }
  }, [user]);

  // @ts-ignore
  window.user = user;

  return (
    <UserContext.Provider value={[user, reloadUser, setUser]}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}