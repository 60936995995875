import { Button, ButtonProps, CircularProgress } from "@material-ui/core";
import { useState } from "react";

export default function PromiseButton({ onClick, disabled, children, ...props }: ButtonProps & { onClick: () => Promise<any>, disabled?: boolean }) {
    const [loading, setLoading] = useState(false);

    return (
        <Button onClick={() => {
            setLoading(true);
            onClick().finally(() => setLoading(false));
        }} {...props} disabled={loading || disabled} style={{ position: 'relative', ...props.style }}>
            {children}
            {loading && <CircularProgress
                size={24}
                style={{
                    // color: green[500],
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                }}
            />}
        </Button>
    )
}