import { createContext, useContext, useEffect, useState } from "react";
import baseCallAPI, { APIOptions, APIResult } from "../utils/callAPI";
import AlertContext from "./Alert";

export interface Auth {
  access_token: string;
  expiry: number;
  user?: User;
}

const AuthContext = createContext<[
  Auth | null,
  <T>(endpoint: string, method?: string, apiOptions?: APIOptions) => Promise<APIResult<T>>,
  (auth: Auth | null) => void,
  () => void]>([null, () => { throw ({ status: 0, success: false, headers: {} }) }, () => { throw new Error("No auth provider") }, () => { throw new Error("No auth provider") }])

export default AuthContext;

export function AuthProvider({ children }: any) {
  const [auth, setAuth] = useState<Auth | null>(() => localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")!) : null);
  const makeAlert = useContext(AlertContext);

  useEffect(() => {
    if (auth) {
      localStorage.setItem("auth", JSON.stringify(auth));
    } else {
      localStorage.removeItem("auth");
    }
  }, [auth]);

  function callAPI<T>(endpoint: string, method?: string, apiOptions?: APIOptions): Promise<APIResult> {
    // return baseCallAPI<T>(endpoint, method, params, successMessage, fetchOptions, auth?.access_token, makeAlert, setAuth);
    return baseCallAPI<T>(endpoint, method, { ...apiOptions, token: auth?.access_token, makeAlert });
  }

  function logout() {
    setAuth(null);
    localStorage.removeItem("auth");
    localStorage.removeItem("user");
  }

  // @ts-ignore
  window.auth = [auth, callAPI, setAuth];

  return (
    <AuthContext.Provider value={[auth, callAPI, setAuth, logout]}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext)
}