import { createContext, useContext, useEffect, useState } from "react";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

export interface AlertInfo {
  severity: "success" | "info" | "warning" | "error";
  message: string;
}

const AlertContext = createContext<(alert: AlertInfo) => void>(() => { throw new Error("No alert provider") })

export default AlertContext;

export function AlertProvider({ children }: any) {
  const [alert, setAlert] = useState<AlertInfo | undefined>(undefined);
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    if (alert) {
      setAlertOpen(true);
      // setTimeout(() => {
      //   setAlertOpen(false);
      // }, 3000);
    }
  }, [alert]);

  console.log("ALERT", alert)

  // @ts-ignore
  window.setAlert = setAlert;

  return (
    <AlertContext.Provider value={setAlert}>
      {children}
      <Snackbar open={alertOpen} autoHideDuration={3000} onClose={() => setAlertOpen(false)}>
        <Alert severity={alert?.severity} style={{ width: '100%' }}>
          {alert?.message}
        </Alert>
      </Snackbar>
    </AlertContext.Provider>
  );
}

export function useAlert() {
  return useContext(AlertContext)
}