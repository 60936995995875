import { useEffect, useState } from "react";
import { useAuth } from "../contexts/Auth";
import { APIOptions, APIResult } from "./callAPI";

export default function useAPI<T>(endpoint: string, method?: string, apiOptions?: APIOptions) {
    const [res, setRes] = useState<APIResult<T> | null>(null);
    const [, callAPI] = useAuth();

    const loadData = () => {
        return callAPI<T>(endpoint, method, apiOptions).then((res) => setRes(res));
    }

    useEffect(() => {
        loadData()
    }, [endpoint]);

    return [res, loadData] as const;
}