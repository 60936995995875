import {
	Button,
	Fade,
	FormControl,
	Grid,
	Input,
	InputAdornment,
	MenuItem,
	Paper,
	Select,
	Snackbar,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";
import NProgress from "nprogress";
import React from "react";
import formatNumber from "../../utils/format-number";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../contexts/Auth";
import { useAlert } from "../../contexts/Alert";

const useStyles = makeStyles((theme) => ({
	classPage: {
		marginTop: theme.spacing(6),
		marginInline: theme.spacing(6),
		color: theme.palette.type === "dark" ? "white" : "black",
	},
	classSection: {
	},
	classSectionItem: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	fab: {
		position: "fixed",
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
}));

const productNumbers = [1, 2, 3, 4];

const months = ["Not started", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export default function Bank({ teamInfo, updateTeamInfo }: { teamInfo: TeamResult; updateTeamInfo: () => Promise<any> }) {
	const classes = useStyles();
	const theme = useTheme();
	const onDesktop = useMediaQuery(theme.breakpoints.up("md"));

	return (
		<Fade in timeout={500}>
			<div className={classes.classPage}>
				<Typography variant="h4" style={{ marginBottom: "20px" }}>
					Manage bank accounts
				</Typography>
				<Grid container spacing={2} direction={onDesktop ? "row" : "row-reverse"}>
					<Grid item xs={12} md={6}>
						<BankTransfer teamInfo={teamInfo} updateTeamInfo={updateTeamInfo} />
					</Grid>
					<Grid item xs={12} md={6}>
						<Balances teamInfo={teamInfo} />
					</Grid>
				</Grid>
			</div>
		</Fade>
	)
}

function Balances({ teamInfo }: { teamInfo: TeamResult }) {
	const month = teamInfo.course.currentMonth

	const stores = Object.keys(teamInfo.stores).map((store) => {
		const storeInfo = teamInfo.stores[store]
		const modifier = storeInfo.modifiers[month] || { Bank: 0 }
		const services = storeInfo.decisions[month]?.Services || []

		let total = 0

		total += storeInfo.outcomes[month - 1]?.Bank || 0
		total += modifier.Bank || 0

		services.forEach(service => {
			total -= service.cost
		})

		return {
			store,
			total
		}
	})

	return <Paper
		style={{
			paddingTop: "20px",
			paddingBottom: "20px",
			paddingLeft: "30px",
			paddingRight: "40px",
		}}
	>
		<Typography style={{ marginBottom: "10px" }} variant="h6">
			Current bank balances:
		</Typography>
		{stores.map((store) => (
			<div style={{ display: "flex" }}>
				<Typography variant="body1">Store {store.store}:</Typography>
				<Typography style={{ marginLeft: "auto" }} variant="body1">
					{formatNumber(
						store.total,
						true
					)}
				</Typography>
			</div>
		))}
	</Paper>;
}

function BankTransfer({ teamInfo, updateTeamInfo }: { teamInfo: TeamResult; updateTeamInfo: () => Promise<any> }) {
	const classes = useStyles();
	const [, callAPI] = useAuth()
	const makeAlert = useAlert()
	const [amount, setAmount] = React.useState(0);
	const [sender, setSender] = React.useState<string>(Object.keys(teamInfo.stores)[0]);
	const [recipient, setRecipient] = React.useState<string>(Object.keys(teamInfo.stores)[1]);

	const theme = useTheme();
	const onDesktop = useMediaQuery(theme.breakpoints.up("md"));

	function purchase() {
		console.log("purchase", sender, recipient, amount)
		if (sender === undefined) {
			// setError("Select which store you want to send the money from.");
			// setSeverity("error");
			// setOpen(true);
			makeAlert({
				severity: "error",
				message: "Select which store you want to send the money from."
			})
			return;
		}
		if (recipient === undefined) {
			// setError("Select which store you want to recieve the money.");
			// setSeverity("error");
			// setOpen(true);
			makeAlert({
				severity: "error",
				message: "Select which store you want to recieve the money."
			})
			return;
		}
		if (amount === undefined || isNaN(amount) || amount < 1) {
			// setError("Select the amount of money that you wish to transfer.");
			// setSeverity("error");
			// setOpen(true);
			makeAlert({
				severity: "error",
				message: "Select the amount of money that you wish to transfer."
			})

			return;
		}
		NProgress.start();
		console.log("sending request");
		callAPI(
			`course/${teamInfo.course.courseId}/store/${sender}/transfer`,
			"POST",
			{ params: { to: recipient, amount: amount * 100 }, successMessage: "Transfer successful!" }
		).then(() => {
			updateTeamInfo();
		}).finally(() => {
			NProgress.done();
		})
	}

	return (
		<Paper
			style={{
				paddingTop: "20px",
				paddingBottom: "20px",
				paddingLeft: "30px",
			}}
		>
			<Typography style={{ marginBottom: "10px" }} variant="h6">
				Transfer money:
			</Typography>
			{Object.keys(teamInfo.stores).length !== 1 ? (
				<Grid container direction="column" spacing={1}>
					<Grid item>
						<div>
							From:{" "}
							<Select value={sender} onChange={(event) => setSender(event.target.value as string)}>
								{Object.keys(teamInfo.stores).map((store) => (
									<MenuItem value={store}>Store {store}</MenuItem>
								))}
							</Select>
						</div>
					</Grid>

					<Grid item>
						<div>
							To:{" "}
							<Select value={recipient} onChange={(event) => setRecipient(event.target.value as string)}>
								{Object.keys(teamInfo.stores).map((store) => (
									<MenuItem value={store}>Store {store}</MenuItem>
								))}
							</Select>
						</div>
					</Grid>
					<Grid item>
						<div>
							Amount:{" "}
							<FormControl
								style={{
									width: "100px",
									margin: "3px",
									marginTop: "-5px",
								}}
								variant="standard"
							>
								<Input
									id="outlined-adornment-amount"
									type="number"
									defaultValue={0}
									required
									onChange={(event) => {
										setAmount(parseFloat(event.target.value));
									}}
									startAdornment={<InputAdornment position="start">$</InputAdornment>}
								// labelWidth={60}
								/>
							</FormControl>
						</div>
					</Grid>
					<Grid item>
						{Object.keys(teamInfo.stores).length !== 1 && (
							<div>
								Note: the transfer will appear in your cost breakdown under services.
							</div>
						)}
					</Grid>
					<Grid item>
						<Button variant="contained" color="primary" onClick={purchase}>
							Transfer
						</Button>
					</Grid>
				</Grid>
			) : (
				<Grid item>
					<Typography variant="body1">
						Get at least 2 stores to manage your bank accounts!
					</Typography>
				</Grid>
			)}
		</Paper>
	);
}
