import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./Auth";

const defaultTeam: Team = {
  teamId: 0,
  courseId: ""
}

const TeamContext = createContext<[
  Team,
  (team: Team) => void
]>([
  defaultTeam,
  () => { throw new Error("No team provider") }
])

export default TeamContext;

export function TeamProvider({ children }: any) {
  const [team, setTeam] = useState<Team>(() => localStorage.getItem("team") ? JSON.parse(localStorage.getItem("team")!) : defaultTeam);


  useEffect(() => {
    if (team) {
      console.log("SETTING TEAM", team)
      localStorage.setItem("team", JSON.stringify(team));
    } else {
      console.log("REMOVING TEAM")
      localStorage.removeItem("team");
    }
  }, [team]);

  // @ts-ignore
  window.team = team;

  return (
    <TeamContext.Provider value={[team, setTeam]}>
      {children}
    </TeamContext.Provider>
  );
}

export function useTeam() {
  return useContext(TeamContext);
}