import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  TextField,
} from "@material-ui/core";
import { Auth, useAuth } from "../contexts/Auth";
import { Email } from "@material-ui/icons";
import { useUser } from "../contexts/User";
import { useAlert } from "../contexts/Alert";
import PromiseButton from "../components/PromiseButton";
import callAPI from "../utils/callAPI";

const CLIENT_ID =
  "924399635605-o407elavspecnpabktdln70o3h6mks8n.apps.googleusercontent.com";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "100px",
    color: theme.palette.text.primary,
  },
  toolbarRight: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },
  dollarIcon: {
    height: "90%",
    width: "90%",
  },
  WCommerce: {
    color: "#FCC000",
  },
}));

export default function Login() {
  const [, , setAuth] = useAuth();
  const [tempAuth, setTempAuth] = React.useState<Auth | null>(null);
  const [teams, setTeams] = React.useState<Team[]>([]);
  const [, , setUser] = useUser();
  const [fallback, setFallback] = React.useState(false);
  const [emailAuthOpen, setEmailAuthOpen] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setFallback(true);
    }, 3000);
  }, []);

  // async function refreshTeams() {
  // 	const response = await callAPI<Team[]>(
  // 		`user

  // React.useEffect(() => {
  // 	if (tempAuth) {
  // 		refreshTeams();
  // 	}
  // }, [tempAuth])

  React.useEffect(() => {
    if (tempAuth) {
      setAuth(tempAuth);
      if (tempAuth.user) {
        setUser(tempAuth.user);
      }
    }
  }, [tempAuth]);

  const classes = useStyles();

  document.title = "Please log in - Retail Simulation";

  async function onGoogleSignIn(gResponse: any) {
    console.log("G RESPONSE", gResponse);
    // NProgress.start();
    const response = await callAPI<Auth>(`auth/authenticate/google`, "POST", {
      params: {
        idtoken: gResponse.credential,
      },
      successMessage: "Successfully logged in",
    });

    const data = response.json;

    console.log("DATA", data);

    setTempAuth(data);
  }

  // @ts-ignore
  window.onGoogleSignIn = onGoogleSignIn;

  return (
    <React.Fragment>
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Retail Simulation
          </Typography>
          <Fade in={fallback} timeout={1000}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <ThemeProvider
                theme={createTheme({
                  palette: {
                    type: "dark",
                    primary: {
                      main: "#222222",
                    },
                    secondary: {
                      main: "#FCC000",
                    },
                  },
                })}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<Email />}
                  onClick={() => setEmailAuthOpen(true)}
                >
                  Sign in with email
                </Button>
                <Typography
                  variant="body1"
                  style={{ color: "white", paddingLeft: 14, paddingRight: 16 }}
                >
                  or
                </Typography>
              </ThemeProvider>
            </Box>
          </Fade>
          <div className={classes.toolbarRight}>
            <div
              id="g_id_onload"
              data-client_id={CLIENT_ID}
              data-ux_mode="popup"
              data-callback="onGoogleSignIn"
              data-auto_select="true"
              data-close_on_tap_outside="false"
            ></div>

            <div
              className="g_id_signin"
              data-type="standard"
              data-shape="rectangular"
              data-theme="outline"
              data-text="signin_with"
              data-size="large"
              data-logo_alignment="left"
            ></div>
          </div>
        </Toolbar>
      </AppBar>
      <Container className={classes.mainContent}>
        <Grid container spacing={10}>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={6}>
              <Typography variant="h2">Welcome to</Typography>
              <Typography variant="h2" className={classes.WCommerce}>
                Retail Simulation
              </Typography>
              <Typography variant="h4">
                Launch a clothing business in the virtual world. Learn how to
                run a business and put your entrepreneur skills to the test.
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <img
                src="/static/images/diagram2.svg"
                className={classes.dollarIcon}
              ></img>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <EmailLoginDialog
        open={emailAuthOpen}
        onClose={() => setEmailAuthOpen(false)}
        setAuth={setTempAuth}
      />
    </React.Fragment>
  );
}

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

function EmailLoginDialog(props: {
  open: boolean;
  onClose: () => void;
  setAuth: (auth: Auth) => void;
}) {
  const [email, setEmail] = React.useState("");
  const [code, setCode] = React.useState("");
  const [step, setStep] = React.useState(0);
  const [, callAPI] = useAuth();
  const [, , setUser] = useUser();
  const setAlert = useAlert();

  const emailValid = validateEmail(email);

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth>
      <DialogTitle>Email sign in</DialogTitle>
      {step === 0 && (
        <>
          <DialogContent>
            <DialogContentText>
              Enter your email address to get a sign in code
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              error={!!email && !emailValid}
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose}>Cancel</Button>
            <PromiseButton
              onClick={async () => {
                await callAPI(`auth/otp/${email}`, "POST");
                setStep(1);
              }}
              disabled={!emailValid}
            >
              Get code
            </PromiseButton>
          </DialogActions>
        </>
      )}
      {step === 1 && (
        <>
          <DialogContent>
            <DialogContentText>
              Check your email for a sign in code. It expires in 5 minutes.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="code"
              label="Sign in code"
              type="number"
              fullWidth
              value={code}
              onChange={(e) => setCode(e.target.value)}
              inputProps={{
                inputMode: "numeric",
                pattern: "^[0-9]*$",
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose}>Cancel</Button>
            <PromiseButton
              onClick={async () => {
                try {
                  const res = await callAPI<Auth>(
                    "auth/authenticate/otp",
                    "POST",
                    {
                      params: {
                        email,
                        otp: code,
                      },
                      successMessage: "Successfully logged in",
                      silent: true,
                    }
                  );

                  props.setAuth(res.json);
                  if (res.json.user) {
                    setUser(res.json.user);
                  }
                } catch (e: any) {
                  if (e.status === 400) {
                    setAlert({
                      severity: "error",
                      message: "Invalid code",
                    });
                  } else if (e.status === 404) {
                    setAlert({
                      severity: "error",
                      message: "Confused email. Let Ben know",
                    });
                  } else {
                    setAlert({
                      severity: "error",
                      message: "Unknown error: " + e.status,
                    });
                  }
                }
              }}
              disabled={!code}
            >
              Sign in
            </PromiseButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
