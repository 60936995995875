import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Route,
  Link as RouterLink,
  Switch as RouterSwitch,
  useHistory,
} from "react-router-dom";

import { Tooltip } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Drawer from "@material-ui/core/Drawer";
import Fade from "@material-ui/core/Fade";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Snackbar from "@material-ui/core/Snackbar";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Update as UpdateIcon } from "@material-ui/icons";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import BarChartIcon from "@material-ui/icons/Equalizer";
import FeedbackIcon from "@material-ui/icons/Feedback";
import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import StoreIcon from "@material-ui/icons/Store";
import MuiAlert from "@material-ui/lab/Alert";
import Rating from "@material-ui/lab/Rating";
import clsx from "clsx";
import NProgress from "nprogress";
import React from "react";
import { HandbookViewer } from "../components/handbook-viewer.js";
import Bank from "./student/bank";
import LoadingOverview from "./student/loading-overview.js";
import MarketResearch from "./student/market-research.js";
import Overview from "./student/overview.js";
import Stores from "./student/stores.js";

import { Divider, Popover } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import preval from "preval.macro";
import { useAuth } from "../contexts/Auth";
import { useUser } from "../contexts/User";
import { useAlert } from "../contexts/Alert";
import JoinTeam from "./JoinTeam";

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    // overflowX: "hidden",
  },
  appBar: {
    zIndex: 1400,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "none",
  },
  drawer: {
    zIndex: theme.zIndex.drawer + 2,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    zIndex: theme.zIndex.drawer + 2,
    width: drawerWidth,
    position: "fixed",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    zIndex: theme.zIndex.drawer + 2,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    position: "fixed",

    width: theme.spacing(6),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  mobileDrawer: {
    width: drawerWidth,
  },
  toolbar: {
    width: "100%",
    paddingLeft: "0px",
  },
  toolbarButtons: {},
  toolbarButton: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: theme.spacing(5),
    color: theme.palette.primary.contrastText,
  },
  toggleDrawerButton: { marginRight: theme.spacing(1) },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    border: "2px solid " + theme.palette.secondary.main,
  },
  toolbarRight: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },
  menuIcon: {
    marginLeft: 2,
    marginRight: theme.spacing(1),
  },
  drawerContainer: {
    overflow: "hidden",
  },
  sidebar: {
    height: "100%",
  },
  skinnySidebar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#ffffff",
  },
  mainContent: {
    flex: "2",
    display: "flex",
    flexDirection: "column",
    // overflowX: "hidden",
  },

  popover: {
    // background: theme.custom.palette.profilePopColor,
    // width: 200,
    // backgroundColor: "rgba(255, 255, 255, .15)",
    // backdropFilter: "blur(5px)",
    // backgroundColor: "rgba(100, 50, 180, 0.9)",
    // backgroundColor: "#3b0087"
    // borderRadius: theme.shape.borderRadius
  },
  container: {
    display: "flex",
    padding: theme.spacing(2),
    flexDirection: "column",
    alignItems: "center",
    width: 300,
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    border: "2px solid " + theme.palette.secondary.main,
    margin: theme.spacing(1),
    // background: theme.palette.background.default
  },
  userInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
  },
  userName: {
    // ...theme.custom.fontFamily.metropolis,
    fontSize: "1rem",
    fontWeight: 500,
  },
  userEmail: {
    // ...theme.custom.fontFamily.roboto,
    fontSize: "0.9rem",
  },
  bar: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonSignout: {
    // ...theme.custom.fontFamily.metropolis,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 2),
    fontSize: "0.8rem",
    fontWeight: 500,
    textTransform: "none",
  },
  textbox: {
    // ...theme.custom.fontFamily.metropolis,
    color: "white",
  },
}));

export const SidebarMenuItem = React.forwardRef((props, ref) => {
  const {
    i,
    icon,
    text,
    route,
    selectedPage,
    setSelectedPage,
    setDrawerOpen,
    ...rest
  } = props;
  return (
    <ListItem
      button
      component={RouterLink}
      to={route}
      selected={selectedPage === route}
      onClick={() => {
        setDrawerOpen(false);
        setSelectedPage(route);
      }}
      ref={ref}
      {...rest}
    >
      <ListItemIcon style={{ paddingLeft: "2px" }}>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
});

export function Routes(props) {
  console.log("dash > student");
  const [teamInfoLoaded, setTeamInfoLoaded] = React.useState(false);
  const [teamInfo, setTeamInfo] = React.useState({});
  const [, callAPI] = useAuth();
  const [joinTeamOpen, setJoinTeamOpen] = React.useState(false);
  const theme = useTheme();
  const onDesktop = useMediaQuery(theme.breakpoints.up("md"));

  React.useEffect(() => {
    document.title = "Dashboard - Retail Simulation";
    NProgress.start();
    // callAPI("GetTeamInfo", user.token, {}, function (err, res, xhr) {
    // 	NProgress.done();
    // 	if (!err) {
    // 		console.log(res);
    // 		setTeamInfo(res);
    // 		setTeamInfoLoaded(true);
    // 	}
    // });
    callAPI("compat/student/team")
      .then((res) => {
        if (Object.keys(res.json.stores).length === 0) {
          setJoinTeamOpen(true);
          return;
        }
        console.log(res);
        setTeamInfo(res.json);
        setTeamInfoLoaded(true);
      })
      .catch((err) => {
        if (err.status === 404) {
          setJoinTeamOpen(true);
        }
      })
      .finally(() => NProgress.done());
  }, []);

  function refreshTeamInfo() {
    return callAPI("compat/student/team")
      .then((res) => {
        if (Object.keys(res.json.stores).length === 0) {
          setJoinTeamOpen(true);
          return;
        }
        console.log(res);
        setTeamInfo(res.json);
        setTeamInfoLoaded(true);
      })
      .catch((err) => {
        if (err.status === 404) {
          setJoinTeamOpen(true);
        }
      });
  }

  return (
    <>
      <JoinTeam
        open={joinTeamOpen && !teamInfoLoaded}
        retry={refreshTeamInfo}
      />
      <div style={{ minHeight: "calc(100vh - 72px)", position: "relative" }}>
        <LoadingOverview
          mobileMode={props.mobileMode}
          teamInfo={teamInfo}
          teamInfoLoaded={teamInfoLoaded}
        />
        <Fade in={teamInfoLoaded} timeout={500}>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
            }}
          >
            {teamInfoLoaded && (
              <RouterSwitch>
                <Route path="/stores">
                  <Stores teamData={teamInfo} />
                </Route>
                <Route path="/bank">
                  <Bank teamInfo={teamInfo} updateTeamInfo={refreshTeamInfo} />
                </Route>
                <Route path="/marketresearch">
                  <MarketResearch
                    teamInfo={teamInfo}
                    updateTeamInfo={refreshTeamInfo}
                  />
                </Route>
                <Route path="/handbook">
                  <HandbookViewer />
                </Route>
                <Route path="*">
                  <Overview teamInfo={teamInfo} />
                </Route>
              </RouterSwitch>
            )}
          </div>
        </Fade>
      </div>
    </>
  );
}

export default function StudentDashboard(props) {
  const classes = useStyles();
  const [auth, callAPI, , logOut] = useAuth();
  const [user] = useUser();
  const makeAlert = useAlert();
  const { darkMode, setDarkModeAndDoTheLocalStorageThing } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({ open: false });
  const theme = useTheme();
  const mobileMode = useMediaQuery(theme.breakpoints.down("sm"));
  const [drawerOpen, setDrawerOpen] = React.useState(!localStorage.shownDrawer);
  if (!localStorage.shownDrawer) {
    setTimeout(() => (localStorage.shownDrawer = "true"), 1000);
  }
  // const [drawerOpen, setDrawerOpen] = React.useState(mobileMode);
  const [feedbackModalOpen, setFeedbackModalOpen] = React.useState(false);
  const [feedbackValues, setFeedbackValues] = React.useState({
    rating: 1,
    type: "feedback",
    text: "",
  });
  const [feedbackSuccessSnackbarOpen, setFeedbackSuccessSnackbarOpen] =
    React.useState(false);

  console.log("mobile mode", mobileMode);
  console.log("drawer open", drawerOpen);

  //const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const [selectedPage, setSelectedPage] = React.useState(
    history.location.pathname
  );

  function openMenu(e) {
    setAnchorEl(e.currentTarget);
  }

  function closeMenu() {
    setAnchorEl(null);
  }

  function logOutHandler() {
    closeMenu();
    makeAlert({
      severity: "info",
      message: "Logging out...",
    });
    callAPI("auth/logout", "DELETE", {
      successMessage: "Logged out successfully.",
    }).finally(() => {
      logOut();
    });
    window.location.reload();
  }

  function sendFeedbackToServer() {
    NProgress.start();
    // callAPI("SendFeedback", user.token, feedbackValues, function (err, res, xhr) {
    // 	NProgress.done();
    // 	if (!err) {
    // 		setFeedbackSuccessSnackbarOpen(true);
    // 	}
    // });
  }

  React.useEffect(() => {
    document.title = "Dashboard - Retail Simulation";
  }, []);

  const drawerContent = (
    <div className={classes.drawerContainer}>
      <List
        className={clsx(drawerOpen ? classes.sidebar : classes.skinnySidebar)}
      >
        {[
          {
            route: "/",
            text: "Overview",
            icon: <HomeIcon fontSize="large" />,
          },
          {
            route: "/marketresearch",
            text: "Market Research",
            icon: <BarChartIcon fontSize="large" />,
          },
          {
            route: "/bank",
            text: "Manage Bank Accounts",
            icon: <AccountBalanceIcon fontSize="large" />,
          },
          {
            route: "/stores",
            text: "Stores",
            icon: <StoreIcon fontSize="large" />,
          },
          {
            route: "/handbook",
            text: "Handbook",
            icon: <MenuBookIcon fontSize="large" />,
          },
        ].map((item, index) => {
          const sidebarItem = (
            <SidebarMenuItem
              i={index}
              icon={item.icon}
              text={drawerOpen && item.text}
              route={item.route}
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
              setDrawerOpen={setDrawerOpen}
            />
          );
          return drawerOpen || mobileMode ? (
            sidebarItem
          ) : (
            <Tooltip title={item.text} placement="right" key={item.route}>
              {sidebarItem}
            </Tooltip>
          );
        })}
      </List>
    </div>
  );

  const iOS = true;
  // const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const drawer = mobileMode ? (
    <SwipeableDrawer
      anchor={"left"}
      open={drawerOpen}
      onClose={() => {
        setDrawerOpen(false);
      }}
      onOpen={() => {
        setDrawerOpen(true);
      }}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      className={classes.mobileDrawer}
    >
      {drawerContent}
    </SwipeableDrawer>
  ) : (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: drawerOpen,
        [classes.drawerClose]: !drawerOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        }),
      }}
    >
      <Toolbar />
      {drawerContent}
    </Drawer>
  );

  function openMenu(e) {
    setAnchorEl(e.currentTarget);
  }

  function closeMenu() {
    setAnchorEl(null);
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Tooltip title="Toggle sidebar">
            <IconButton
              edge="start"
              className={clsx(
                classes.toolbarButton,
                classes.toggleDrawerButton
              )}
              onClick={() => {
                setDrawerOpen(!drawerOpen);
              }}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>

          {/*<Hidden smUp implementation="js">
						<IconButton color="inherit" aria-label="open drawer" edge="start" onClick={setDrawOpen(true)}>
							<MenuIcon />
						</IconButton>
					</Hidden>*/}
          <Typography
            style={{ marginLeft: user.teacher && "30px" }}
            variant="h5"
          >
            <Link
              variant="inherit"
              color="inherit"
              component={RouterLink}
              to={"/"}
            >
              Student dashboard
            </Link>
          </Typography>
          <div className={classes.toolbarRight}>
            <Tooltip title="Send Feedback">
              <IconButton
                className={classes.toolbarButton}
                onClick={() => {
                  setFeedbackModalOpen(true);
                }}
              >
                <FeedbackIcon />
              </IconButton>
            </Tooltip>
            <Avatar
              alt={user.fullName}
              src={user.imageUrl}
              className={classes.avatar}
              onClick={openMenu}
            />
            {/* <Menu
							id="user-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={closeMenu}
						>
							{/*<Modal component={<MenuItem onClick={closeMenu}><InfoOutlinedIcon  className={classes.menuIcon} />About</MenuItem>} title="About WCommerce" content={[{title: 'About', content: 'WCommerce is a web app that is designed to replicate the functionality of the original WC Commerce Markert Simulation but with a user freindly GUI to interface with rather than spreadsheets.'}, {title: 'Credits', content: 'Original concept: WC Commerce teachers<br>Front end (website): Zade Viggers<br>Back end (calcuations and databases): Ben Hong'}]} />
							<Modal component={<MenuItem onClick={closeMenu}><HelpOutlineOutlinedIcon className={classes.menuIcon} />Help</MenuItem>} title="Get Help" content={[{title: 'Stuck with something?', content: 'Please contact your teacher ask them to explain a feature or mechanic.'}, {title: 'Something broke?', content: 'Please contact your teacher or one of the current maintainers: Zade Viggers (19073@wc.school.nz), Ben Hong (19458@wc.school.nz)'}, {title: 'Feature request/suggestion?', content: 'Please csend it to your teacher or one of the current maintainers: Zade Viggers (19073@wc.school.nz), Ben Hong (19458@wc.school.nz)'}]} />
							<Modal component={<MenuItem onClick={closeMenu}><BuildOutlinedIcon className={classes.menuIcon} />Patch notes</MenuItem>} title="Patch Notes" content={patchNotes} />
							*/}
            {/* <MenuItem
							onClick={() => {
								if (darkMode === "system") {
									setDarkModeAndDoTheLocalStorageThing(true);
								} else if (darkMode) {
									setDarkModeAndDoTheLocalStorageThing(false);
								} else {
									setDarkModeAndDoTheLocalStorageThing("system");
								}
							}}
						>
							<Brightness4Icon className={classes.menuIcon} />
							Dark mode: {darkMode === "system" ? "System" : darkMode ? "Enabled" : "Disabled"}
						</MenuItem>
						<MenuItem onClick={logOut}>
							<ExitToAppIcon className={classes.menuIcon} />
							Logout
						</MenuItem>
					</Menu> */}
            <Popover
              open={!!anchorEl}
              // id="frosted"
              anchorEl={anchorEl}
              onClose={closeMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div className={classes.container}>
                <Avatar
                  alt={user.fullName}
                  className={classes.avatar}
                  src={user.imageUrl || user.picture}
                  imgProps={{ style: { width: "100%" } }}
                  // onLoad={(a) => (a.target.className = "animate")}
                ></Avatar>
                <div className={classes.userInfo}>
                  <Typography
                    className={classes.userName}
                    variant="h6"
                    component="span"
                    color="textPrimary"
                  >
                    {user.name}
                  </Typography>
                  <Typography
                    className={classes.userEmail}
                    variant="body1"
                    component="span"
                    color="textSecondary"
                  >
                    {user.email}
                  </Typography>
                </div>
              </div>
              <Typography
                variant="caption"
                style={{
                  float: "left",
                  color: "rgb(0, 0, 0, 0.5)",
                  marginLeft: "10px",
                  marginTop: "-10px",
                  marginBottom: "-4px",
                }}
              >
                Version: {preval`module.exports = new Date().toLocaleString();`}
              </Typography>
              <Divider />
              <List style={{ padding: "0 0 0 0" }}>
                <ListItem
                  button
                  onClick={() => {
                    setFeedbackModalOpen(true);
                  }}
                >
                  <ListItemIcon>
                    <FeedbackIcon />
                  </ListItemIcon>
                  <ListItemText primary="Send feedback" />
                </ListItem>
                {window.updateAvailable && (
                  <ListItem
                    button
                    onClick={() => {
                      window.updateApp();
                    }}
                  >
                    <ListItemIcon>
                      <UpdateIcon />
                    </ListItemIcon>
                    <ListItemText primary="Update" />
                  </ListItem>
                )}
                <ListItem
                  button
                  onClick={() =>
                    setDarkModeAndDoTheLocalStorageThing(!darkMode)
                  }
                >
                  <ListItemIcon>
                    <Checkbox
                      // edge="start"
                      style={{ marginLeft: -8 }}
                      checked={!!darkMode}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Dark mode" />
                </ListItem>
              </List>
              <Divider />
              <div className={classes.bar}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={logOutHandler}
                  classes={{ root: classes.buttonSignout }}
                >
                  Sign out
                </Button>
                {/* <Button variant="outlined" size="small" onClick={refreshPermissions} classes={{ root: classes.buttonPermissions }}>Refresh permissions</Button> */}
              </div>
            </Popover>
          </div>
        </Toolbar>
      </AppBar>
      {drawer}
      {mobileMode ? null : (
        <div>
          <Backdrop
            className={classes.backdrop}
            open={drawerOpen}
            onClick={() => setDrawerOpen(false)}
          />
        </div>
      )}

      <div
        className={classes.mainContent}
        style={{
          width: mobileMode ? "100%" : "calc(100% - 72px)",
          marginLeft: mobileMode ? "0px" : "72px",
        }}
      >
        <Toolbar position="fixed" />
        <Routes mobileMode={mobileMode} />
      </div>

      <Dialog
        open={feedbackModalOpen}
        onClose={() => {
          setFeedbackModalOpen(false);
        }}
      >
        <DialogTitle id="form-dialog-title">
          {feedbackValues.type === "feedback"
            ? "Send feedback about Retail Simulation"
            : "Report an issue with Retail Simulation"}
        </DialogTitle>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setFeedbackModalOpen(false);
            sendFeedbackToServer();
          }}
        >
          <FormControl>
            <DialogContent>
              <DialogContentText>
                We're working hard on making Retail Simulation the best possible
                experience for you
                {feedbackValues.type === "feedback"
                  ? " and we would love to hear what you think about it!"
                  : ". To help us fix it as soon as possible, please describe the issue in detail and explain how you encountered it."}
                {feedbackValues.type === "feedback"
                  ? null
                  : !user.teacher
                  ? " Please only report issues with the website here. If some numbers are wrong, please talk to your teacher."
                  : null}
              </DialogContentText>
              <TextField
                margin="dense"
                id="feedback-message"
                label={
                  feedbackValues.type === "feedback"
                    ? "Feedback"
                    : "Describe the issue"
                }
                placeholder={
                  feedbackValues.type === "feedback"
                    ? "I love Retail Simulation because the website looks so dang good."
                    : "I'm having a problem with... [describe what the problem is with]. I can see... [describe what you can see]. I think that I should be seeing... [describe what you whink you should be seeing]. To make this happen I... [list of steps to replicate the problem]."
                }
                variant="filled"
                color="secondary"
                multiline={true}
                rows={3}
                fullWidth={true}
                value={feedbackValues.message}
                onChange={(e) => {
                  setFeedbackValues({
                    ...feedbackValues,
                    text: e.target.value,
                  });
                }}
                required={true}
              />
              <Box m={0} borderColor="transparent" component="fieldset">
                <Typography component="legend">
                  {feedbackValues.type === "feedback"
                    ? "Rate your experience with Retail Simulation"
                    : "How urgent is your issue?"}
                </Typography>
                <Rating
                  name="feedback-rating"
                  size="large"
                  value={feedbackValues.rating}
                  onChange={(e, newValue) => {
                    // newValue only works in this component btw
                    setFeedbackValues({
                      ...feedbackValues,
                      rating: newValue,
                    });
                  }}
                  required={true}
                />
              </Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={feedbackValues.type === "request" ? true : false}
                    onChange={(e) => {
                      setFeedbackValues({
                        ...feedbackValues,
                        type: e.target.checked ? "request" : "feedback",
                      });
                    }}
                    name="feedback-request-toggle"
                  />
                }
                label="Support request"
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setFeedbackModalOpen(false);
                }}
                color="primary"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setFeedbackModalOpen(false);
                  sendFeedbackToServer();
                }}
                variant="contained"
                color="secondary"
                disabled={
                  feedbackValues.text.length < 5 ||
                  feedbackValues.text.length > 600
                }
              >
                Submit
              </Button>
            </DialogActions>
          </FormControl>
        </form>
      </Dialog>
      <Snackbar
        autoHideDuration={2500}
        onClose={() => setFeedbackSuccessSnackbarOpen(false)}
        open={feedbackSuccessSnackbarOpen}
      >
        <MuiAlert elevation={6} variant="filled" severity="success">
          {feedbackValues.type === "feedback"
            ? "Feedback "
            : "Support request "}
          submitted!
        </MuiAlert>
      </Snackbar>
      <Snackbar
        autoHideDuration={2500}
        onClose={() => setState({ open: false })}
        open={state.open}
      >
        <MuiAlert elevation={6} variant="filled" severity="success">
          Successfully logged in as a student
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
