import { AlertInfo } from "../contexts/Alert";

export interface APIResult<T = any> {
    status: number;
    success: boolean;
    json: T;
    headers: any;
}

// export default async function callAPI<T>(
//     endpoint: string,
//     method: string,
//     params?: any,
//     successMessage?: string,
//     fetchOptions?: any,
//     token?: string,
//     makeAlert?: (alert: AlertInfo) => void,
//     setUser?: (user: any) => void
// ): Promise<APIResult> {

export interface APIOptions {
    method?: string;
    silent?: boolean;
    params?: any;
    successMessage?: string;
    fetchOptions?: any;
    token?: string;
    makeAlert?: (alert: AlertInfo) => void;
}

export default async function callAPI<T>(
    endpoint: string,
    method = "GET",
    {
        params,
        silent,
        successMessage,
        fetchOptions,
        token,
        makeAlert
    }: APIOptions
): Promise<APIResult<T>> {
    console.log("API call:", endpoint, method, params);

    // @ts-ignore
    const promise = window?.loadCache?.[endpoint]
    // @ts-ignore
    delete window?.loadCache?.[endpoint];

    let response = await promise;

    response ||= await fetch(
        "/api/v1/" + endpoint,
        {
            headers: {
                "Content-Type": "application/json",
                ...(token && { Authorization: "Bearer " + token }),
                ...(fetchOptions?.headers),
            },
            method: method,
            ...(params && { body: JSON.stringify(params) }),
            ...(fetchOptions),
        }
    );

    const { status, headers } = response;
    const success = status.toString().startsWith("2");
    const json = success && status !== 204 && (await response.json());

    if (success) {
        if (successMessage) {
            console.log(successMessage, makeAlert)
            makeAlert?.({
                message: successMessage,
                severity: "success",
            });
        }
    } else {
        if (!silent) {
            let message = "";

            try {
                const parser = new DOMParser();

                const doc = parser.parseFromString(await response.text(), "text/html");

                message = doc.querySelector("p")?.textContent || "";
            } catch (e) {
                console.log(e);
            }

            const makeError = (error: string) => makeAlert?.({
                message: error + (message.length && message.length < 50 ? ' "' + message + '"' : ""),
                severity: "error",
            });

            switch (status) {
                case 400:
                    makeError("Bad request.");
                    break;
                case 401:
                    makeError("Invalid token, try signing in again.");
                    // setUser?.(null);
                    // window.location.reload();
                    break;
                case 403:
                    makeError(
                        "Insufficient permissions."
                    );
                    break;
                case 404:
                    makeError(
                        "Resource not found."
                    );
                    break;
                case 405:
                    makeError("Invalid endpoint.");
                    break;
                case 409:
                    makeError("Conflict. It may already exist.");
                    break;
                default:
                    makeError("Unknown error " + status + ", try contacting Ben.");
            }
        }

        throw { status, success, headers };
    }

    return { status, success, json, headers } as APIResult<T>;
}

// @ts-ignore
window.callAPI = callAPI;