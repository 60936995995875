export function arrayOfArraysToCSV(arrayToConvert) {
	let csvData = "";
	arrayToConvert.forEach((row) => {
		csvData += row.join(",");
		csvData += "\n";
	});
	return csvData;
}

export function downloadCSV(csvData, title) {
	const downloader = document.createElement("a");
	downloader.setAttribute(
		"href",
		"data:text/csv;charset=utf-8," + encodeURI(csvData)
	);
	downloader.setAttribute("target", "_blank");
	downloader.setAttribute(
		"download",
		(title || Math.floor(Math.random() * 100000000) + "") + ".csv"
	);
	console.log(downloader);
	downloader.click();
}
