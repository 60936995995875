import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import {
  Route,
  Switch as RouterSwitch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { arrayOfArraysToCSV, downloadCSV } from "../../utils/csv";

import Alert from "@material-ui/lab/Alert";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import ExportIcon from "@material-ui/icons/SaveAlt";
import Fade from "@material-ui/core/Fade";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import React from "react";
import RelatedHandbookSections from "../../components/handbook-viewer";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import Tab from "@material-ui/core/Tab";
import NProgress from "nprogress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import formatNumber from "../../utils/format-number";
import { makeStyles } from "@material-ui/core/styles";
import callAPI from "../../utils/api.js";
import { useTheme } from "@material-ui/core";
import { useAuth } from "../../contexts/Auth";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      {/* <GridToolbarExport style={{ marginLeft: "auto" }} /> */}
    </GridToolbarContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  classPage: {
    marginTop: theme.spacing(6),
    marginInline: theme.spacing(6),
    color: theme.palette.type === "dark" ? "white" : "black",
  },
  storePage: {},
  classSection: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  classSectionItem: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  smallAvatar: {
    width: "40px",
    height: "40px",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  accordionSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  clickable: {
    cursor: "pointer",
  },
  dataGridWrapper: {
    width: "100%",
    height: theme.spacing(40),
    flex: 1,
    overflow: "auto",
  },
  dataGrid: { overflow: "auto", color: "white" },
  tabs: {
    color: theme.palette.type === "dark" ? "white" : "black",
  },
}));

const months = [
  "Not started",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function LocationDetails(props) {
  const classes = useStyles();
  const { locationData } = props;
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { storeNumber } = useParams();

  return <div className={classes.storePage}></div>;
}

export function StoreDetails(props) {
  const classes = useStyles();
  const [, callAPI] = useAuth();
  const { teamData } = props;
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { storeNumber } = useParams();
  let storeData = teamData.stores[storeNumber];
  storeData.decisions["0"] = {};
  const lastMonth = Math.max(...Object.keys(storeData.outcomes));
  const latestOutcomes = storeData.outcomes[lastMonth];
  const latestDecisions =
    storeData.decisions[Math.max(...Object.keys(storeData.decisions))];
  const productNumbers = [1, 2, 3, 4];
  let productNames = {};
  let i;
  let number;
  let productName;
  for (i in productNumbers) {
    number = productNumbers[i];
    productName =
      latestDecisions["Product" + number] === undefined
        ? "None"
        : latestDecisions["Product" + number];
    productNames[number] = productName;
  }
  let productPurchases = {};
  for (i in productNumbers) {
    number = productNumbers[i];
    productPurchases[number] = latestDecisions["ProductPurchases" + number];
  }
  let productPrice = {};
  for (i in productNumbers) {
    number = productNumbers[i];
    productPrice[number] = latestDecisions["ProductPrice" + number];
  }
  const [tab, setTab] = React.useState(
    Math.max(...Object.keys(storeData.outcomes)) -
    Math.min(...Object.keys(storeData.outcomes)) +
    1
  );
  const [staff, setStaff] = React.useState(latestDecisions["Staff"]);
  const [wage, setWage] = React.useState(latestDecisions["Wage"]);
  const [LoanPrincipalRepayment, setLoanPrincipalRepayment] = React.useState(
    latestDecisions["LoanPrincipalRepayment"]
  );
  const [promotion, setPromotion] = React.useState(
    latestDecisions["Promotion"]
  );
  const [Product, setProduct] = React.useState(productNames);
  const [ProductPurchases, setProductPurchases] =
    React.useState(productPurchases);
  const [ProductPrice, setProductPrice] = React.useState(productPrice);
  const [error, setError] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const month = tab + Math.min(...Object.keys(storeData.outcomes));

  const products = {
    "B. Mens Casual": {
      Product: "B. Mens Casual",
      Minimum_Order: 50,
      Cost_per_item: 10,
      RRP: 20,
      Demand: 4477,
    },
    "B. Mens Formal": {
      Product: "B. Mens Formal",
      Minimum_Order: 20,
      Cost_per_item: 20,
      RRP: 40,
      Demand: 707,
    },
    "B. Womens Casual": {
      Product: "B. Womens Casual",
      Minimum_Order: 50,
      Cost_per_item: 10,
      RRP: 20,
      Demand: 5632,
    },
    "B. Womens Formal": {
      Product: "B. Womens Formal",
      Minimum_Order: 20,
      Cost_per_item: 20,
      RRP: 40,
      Demand: 895,
    },
    "B. Boys": {
      Product: "B. Boys",
      Minimum_Order: 50,
      Cost_per_item: 5,
      RRP: 10,
      Demand: 1414,
    },
    "B. Girls": {
      Product: "B. Girls",
      Minimum_Order: 50,
      Cost_per_item: 5,
      RRP: 10,
      Demand: 1532,
    },
    "D. Mens Casual": {
      Product: "D. Mens Casual",
      Minimum_Order: 25,
      Cost_per_item: 40,
      RRP: 84,
      Demand: 1602,
    },
    "D. Mens Formal": {
      Product: "D. Mens Formal",
      Minimum_Order: 10,
      Cost_per_item: 80,
      RRP: 168,
      Demand: 778,
    },
    "D. Womens Casual": {
      Product: "D. Womens Casual",
      Minimum_Order: 25,
      Cost_per_item: 40,
      RRP: 84,
      Demand: 2074,
    },
    "D. Womens Formal": {
      Product: "D. Womens Formal",
      Minimum_Order: 10,
      Cost_per_item: 80,
      RRP: 168,
      Demand: 990,
    },
    "D. Boys": {
      Product: "D. Boys",
      Minimum_Order: 25,
      Cost_per_item: 25,
      RRP: 52.5,
      Demand: 471,
    },
    "D. Girls": {
      Product: "D. Girls",
      Minimum_Order: 25,
      Cost_per_item: 25,
      RRP: 52.5,
      Demand: 589,
    },
    "O. Mens": {
      Product: "O. Mens",
      Minimum_Order: 20,
      Cost_per_item: 50,
      RRP: 120,
      Demand: 1390,
    },
    "O. Womens": {
      Product: "O. Womens",
      Minimum_Order: 20,
      Cost_per_item: 50,
      RRP: 120,
      Demand: 1249,
    },
    "O. Kids": {
      Product: "O. Kids",
      Minimum_Order: 20,
      Cost_per_item: 25,
      RRP: 60,
      Demand: 1202,
    },
    None: {
      Product: "None",
      Minimum_Order: 0,
      Cost_per_item: 0,
      RRP: 0,
      Demand: 0,
    },
  };

  const numbers = [
    "zero",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
  ];

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  let productStocks = {};

  number = undefined;

  for (number of productNumbers) {
    productStocks[latestOutcomes["Product" + number.toString()]] =
      latestOutcomes["ProductStock" + number.toString()];
  }

  const otherStores = Object.keys(teamData.stores).filter(function (item) {
    return item != storeNumber;
  });

  let totalStock = 0;

  console.log(
    "other stores",
    otherStores,
    "all stores",
    Object.keys(teamData.stores)
  );

  console.log("total stock", totalStock);

  console.log("product stocks", productStocks);
  productNumbers.map((i) => {
    totalStock += productStocks[Product[i]]
      ? productStocks[Product[i]] + ProductPurchases[i]
      : ProductPurchases[i];
  });

  console.log("total stock from current store", totalStock);

  otherStores.forEach((store) => {
    if (teamData.stores[store].decisions[parseInt(lastMonth) + 1]) {
      productNumbers.map((i) => {
        if (
          teamData.stores[store].decisions[parseInt(lastMonth) + 1][
          "Product" + i.toString()
          ] !== "None"
        ) {
          totalStock +=
            teamData.stores[store].decisions[parseInt(lastMonth) + 1][
            "ProductPurchases" + i.toString()
            ];
          console.log(
            "values",
            Object.values(teamData.stores[store].outcomes[lastMonth])
          );
          let outcomesIndex = Object.values(
            teamData.stores[store].outcomes[lastMonth]
          ).indexOf(
            teamData.stores[store].decisions[parseInt(lastMonth) + 1][
            "Product" + i.toString()
            ]
          );
          console.log("index", outcomesIndex);
          if (outcomesIndex !== -1) {
            totalStock += Object.values(
              teamData.stores[store].outcomes[lastMonth]
            )[outcomesIndex + 8];
          }
        }
      });
    }
  });

  let storageCapacity = 0;

  Object.keys(teamData.stores).map((store) => {
    storageCapacity += teamData.stores[store].location.Storage_Capacity;
  });

  function submitDecisions() {
    if (staff === undefined) {
      setError("Select how many staff you want at your store");
      setSeverity("error");
      setOpen(true);
      return;
    }
    if (staff === 0) {
      setError(
        "Are you sure about having no staff? Who's gonna sell your stuff?"
      );
      setSeverity("warning");
      setOpen(true);
    }
    if (wage === undefined) {
      setError("Select what wage you're gonna pay your staff.");
      setSeverity("error");
      setOpen(true);
      return;
    }
    if (totalStock > storageCapacity) {
      setError("You stocked too much, lower your purchases.");
      setSeverity("error");
      setOpen(true);
      return;
    }
    if (staff === undefined) {
      setError("Select how many staff you want at your store");
      setSeverity("error");
      setOpen(true);
      return;
    }
    for (i in productNumbers) {
      number = productNumbers[i];
      if (Product[number] === undefined) {
        setProduct((prev) => ({ ...prev, [number]: "None" }));
      }
      console.log("name", Product[number]);
      if (Product[number] !== "None" && Product[number] !== undefined) {
        console.log("Checking product details");
        if (ProductPrice[number] === undefined || isNaN(ProductPrice[number])) {
          console.log("price error");
          setError("Select what price you want to sell " + Product[number]);
          setSeverity("error");
          setOpen(true);
          return;
        } else if (
          ProductPurchases[number] === undefined ||
          isNaN(ProductPurchases[number])
        ) {
          console.log("purchases error");
          setError(
            "Select how much of " + Product[number] + " you want to buy."
          );
          setSeverity("error");
          setOpen(true);
          return;
        } else if (parseInt(ProductPurchases[number]) < 0) {
          console.log("purchases less than 0 error");
          setError(
            "You can't throw away or return units of " + Product[number] + "."
          );
          setSeverity("error");
          setOpen(true);
          return;
        }
      }
    }

    if (LoanPrincipalRepayment > latestOutcomes.Loan) {
      setLoanPrincipalRepayment(latestOutcomes.Loan);
      setError(
        "You tried to repay more than what your loan actually is. The repayment amount was set to the amount of your loan."
      );
      setSeverity("warning");
      setOpen(true);
    }

    const decisions = {};
    const purchases = [];
    for (i in productNumbers) {
      number = productNumbers[i];
      if (!Product[number] || Product[number] === "None") {
        continue;
      }
      // decisions["ProductPurchases" + number] = ProductPurchases[number];
      // decisions["ProductPrice" + number] = ProductPrice[number];
      purchases.push({
        productName: Product[number],
        quantity: ProductPurchases[number],
        price: Math.round(
          products[Product[number]].RRP * ProductPrice[number] * 100
        ),
      });
    }
    decisions.purchases = purchases;
    decisions.loanPrincipalRepayment = LoanPrincipalRepayment * 100;
    decisions.staff = staff;
    decisions.wageType = wage;
    decisions.promotion = promotion;
    if (LoanPrincipalRepayment === undefined || isNaN(LoanPrincipalRepayment)) {
      setLoanPrincipalRepayment(0);
      decisions.LoanPrincipalRepayment = 0;
    }
    if (promotion === "None") {
      decisions.promotion = undefined;
    }
    // NProgress.start();
    console.log("sending request");
    callAPI(
      `course/${teamData.course.courseId}/store/${storeNumber}/decision`,
      "POST",
      {
        params: decisions,
        successMessage: "Decisions submitted successfully!",
      }
    );
  }

  const handleClose = () => {
    setOpen(false);
  };

  console.log("month", month);
  console.log("tab", tab);
  console.log("product", Product);

  return (
    <Fade in timeout={500}>
      <div className={classes.storePage}>
        {/* <Typography variant="h4">10CMM {className} store #{storeNumber}</Typography> */}
        <Tabs
          value={tab}
          onChange={handleChange}
          classes={{ root: classes.tabs }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {Object.keys(storeData.outcomes).map((outcomeMonth) => (
            <Tab label={months[outcomeMonth]} key={outcomeMonth} />
          ))}
          <Tab
            label={months[Math.max(...Object.keys(storeData.outcomes)) + 1]}
          />
        </Tabs>

        <div style={{ paddingInline: 16 }}>
          {month === Math.min(...Object.keys(storeData.outcomes)) ? (
            <Paper style={{ paddingTop: "1px", width: "80%" }}>
              {/* <Divider /> */}
              <Box display="flex" flexDirection="row">
                <Typography variant="h6" style={{ margin: "10px" }}>
                  Startup info
                </Typography>
                <Button
                  size="small"
                  startIcon={<ExportIcon />}
                  style={{
                    marginLeft: "auto",
                    marginRight: "16px",
                    flexGrow: "0",
                  }}
                  onClick={() => {
                    downloadCSV(
                      arrayOfArraysToCSV([
                        [
                          "Starting capital",
                          "Starting loan",
                          "Location",
                          "Bond",
                          "Fixtures and fittings",
                          "Bank",
                        ],
                        [
                          storeData.outcomes[month].Bank -
                          storeData.outcomes[month].Loan +
                          storeData.location.Bond +
                          storeData.location.Cost_of_Fittings,
                          storeData.outcomes[month].Loan,
                          storeData.location.Name,
                          storeData.location.Bond,
                          storeData.location.Cost_of_Fittings,
                          storeData.outcomes[month].Bank,
                        ],
                      ]),
                      `Startup info_${storeData.location.Name}`
                    );
                  }}
                >
                  Export
                </Button>
              </Box>
              <Divider />
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Starting capital</TableCell>
                    <TableCell align="right">
                      {formatNumber(
                        storeData.outcomes[month].Bank -
                        storeData.outcomes[month].Loan +
                        storeData.location.Bond +
                        storeData.location.Cost_of_Fittings,
                        true
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Starting loan</TableCell>
                    <TableCell align="right">
                      {formatNumber(storeData.outcomes[month].Loan, true)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Location</TableCell>
                    <TableCell align="right">
                      {storeData.location.Name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Bond</TableCell>
                    <TableCell align="right">
                      {formatNumber(storeData.location.Bond, true)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Fixtures and fittings</TableCell>
                    <TableCell align="right">
                      {formatNumber(storeData.location.Cost_of_Fittings, true)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Bank</TableCell>
                    <TableCell align="right">
                      {formatNumber(storeData.outcomes[month].Bank, true)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          ) : month <= Math.max(...Object.keys(storeData.outcomes)) ? (
            <Grid container spacing={2}>
              <Grid item sx={12} md={8}>
                <Paper style={{ paddingTop: "1px" }}>
                  <Box display="flex" flexDirection="row">
                    <Typography variant="h6" style={{ margin: "10px" }}>
                      Products
                    </Typography>
                    <Button
                      size="small"
                      startIcon={<ExportIcon />}
                      style={{
                        marginLeft: "auto",
                        marginRight: "16px",
                        flexGrow: "0",
                      }}
                      onClick={() => {
                        downloadCSV(
                          arrayOfArraysToCSV([
                            [
                              "Product",
                              "Purchases",
                              "Price",
                              "Stock",
                              "Sales",
                              "Remaining stock",
                              "Revenue",
                            ],
                            [
                              storeData.outcomes[month].Product1,
                              storeData.decisions[month].ProductPurchases1,
                              Math.round(
                                storeData.decisions[month].ProductPrice1 * 100
                              ),
                              storeData.outcomes[month].ProductStock1 +
                              storeData.outcomes[month].ProductSales1,
                              storeData.outcomes[month].ProductSales1,
                              storeData.outcomes[month].ProductStock1,
                              storeData.outcomes[month].ProductSales1 *
                              products[storeData.outcomes[month].Product1]
                                .RRP *
                              storeData.decisions[month].ProductPrice1,
                            ],
                            [
                              storeData.outcomes[month].Product2,
                              storeData.decisions[month].ProductPurchases2,
                              Math.round(
                                storeData.decisions[month].ProductPrice2 * 100
                              ),
                              storeData.outcomes[month].ProductStock2 +
                              storeData.outcomes[month].ProductSales2,
                              storeData.outcomes[month].ProductSales2,
                              storeData.outcomes[month].ProductStock2,
                              storeData.outcomes[month].ProductSales2 *
                              products[storeData.outcomes[month].Product2]
                                .RRP *
                              storeData.decisions[month].ProductPrice2,
                            ],
                            [
                              storeData.outcomes[month].Product3,
                              storeData.decisions[month].ProductPurchases3,
                              Math.round(
                                storeData.decisions[month].ProductPrice3 * 100
                              ),
                              storeData.outcomes[month].ProductStock3 +
                              storeData.outcomes[month].ProductSales3,
                              storeData.outcomes[month].ProductSales3,
                              storeData.outcomes[month].ProductStock3,
                              storeData.outcomes[month].ProductSales3 *
                              products[storeData.outcomes[month].Product3]
                                .RRP *
                              storeData.decisions[month].ProductPrice3,
                            ],
                            [
                              storeData.outcomes[month].Product4,
                              storeData.decisions[month].ProductPurchases4,
                              Math.round(
                                storeData.decisions[month].ProductPrice4 * 100
                              ),
                              storeData.outcomes[month].ProductStock4 +
                              storeData.outcomes[month].ProductSales4,
                              storeData.outcomes[month].ProductSales4,
                              storeData.outcomes[month].ProductStock4,
                              storeData.outcomes[month].ProductSales4 *
                              products[storeData.outcomes[month].Product4]
                                .RRP *
                              storeData.decisions[month].ProductPrice4,
                            ],
                          ]),
                          `Products_${storeData.location.Name}_${months[month]}`
                        );
                      }}
                    >
                      Export
                    </Button>
                  </Box>
                  <Divider />
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell width="25%">Product</TableCell>
                        <TableCell width="10%" align="right">
                          Purchases
                        </TableCell>
                        <TableCell width="8%" align="right">
                          Price
                        </TableCell>
                        <TableCell width="8%" align="right">
                          Stock
                        </TableCell>
                        <TableCell width="8%" align="right">
                          Sales
                        </TableCell>
                        <TableCell width="32%" align="right">
                          Remaining stock
                        </TableCell>
                        <TableCell width="9%" align="right">
                          Revenue
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {storeData.outcomes[month].Product1}
                        </TableCell>
                        <TableCell align="right">
                          {storeData.decisions[month].ProductPurchases1}
                        </TableCell>
                        <TableCell align="right">
                          {Math.round(
                            storeData.decisions[month].ProductPrice1 * 100
                          ).toString() + "%"}
                        </TableCell>
                        <TableCell align="right">
                          {storeData.outcomes[month].ProductStock1 +
                            storeData.outcomes[month].ProductSales1}
                        </TableCell>
                        <TableCell align="right">
                          {storeData.outcomes[month].ProductSales1}
                        </TableCell>
                        <TableCell align="right">
                          {storeData.outcomes[month].ProductStock1}
                        </TableCell>
                        <TableCell align="right">
                          {formatNumber(
                            storeData.outcomes[month].ProductSales1 *
                            products[storeData.outcomes[month].Product1].RRP *
                            storeData.decisions[month].ProductPrice1,
                            true
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {storeData.outcomes[month].Product2}
                        </TableCell>
                        <TableCell align="right">
                          {storeData.decisions[month].ProductPurchases2}
                        </TableCell>
                        <TableCell align="right">
                          {Math.round(
                            storeData.decisions[month].ProductPrice2 * 100
                          ).toString() + "%"}
                        </TableCell>
                        <TableCell align="right">
                          {storeData.outcomes[month].ProductStock2 +
                            storeData.outcomes[month].ProductSales2}
                        </TableCell>
                        <TableCell align="right">
                          {storeData.outcomes[month].ProductSales2}
                        </TableCell>
                        <TableCell align="right">
                          {storeData.outcomes[month].ProductStock2}
                        </TableCell>
                        <TableCell align="right">
                          {formatNumber(
                            storeData.outcomes[month].ProductSales2 *
                            products[storeData.outcomes[month].Product2].RRP *
                            storeData.decisions[month].ProductPrice2,
                            true
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {storeData.outcomes[month].Product3}
                        </TableCell>
                        <TableCell align="right">
                          {storeData.decisions[month].ProductPurchases3}
                        </TableCell>
                        <TableCell align="right">
                          {Math.round(
                            storeData.decisions[month].ProductPrice3 * 100
                          ).toString() + "%"}
                        </TableCell>
                        <TableCell align="right">
                          {storeData.outcomes[month].ProductStock3 +
                            storeData.outcomes[month].ProductSales3}
                        </TableCell>
                        <TableCell align="right">
                          {storeData.outcomes[month].ProductSales3}
                        </TableCell>
                        <TableCell align="right">
                          {storeData.outcomes[month].ProductStock3}
                        </TableCell>
                        <TableCell align="right">
                          {formatNumber(
                            storeData.outcomes[month].ProductSales3 *
                            products[storeData.outcomes[month].Product3].RRP *
                            storeData.decisions[month].ProductPrice3,
                            true
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {storeData.outcomes[month].Product4}
                        </TableCell>
                        <TableCell align="right">
                          {storeData.decisions[month].ProductPurchases4}
                        </TableCell>
                        <TableCell align="right">
                          {Math.round(
                            storeData.decisions[month].ProductPrice4 * 100
                          ).toString() + "%"}
                        </TableCell>
                        <TableCell align="right">
                          {storeData.outcomes[month].ProductStock4 +
                            storeData.outcomes[month].ProductSales4}
                        </TableCell>
                        <TableCell align="right">
                          {storeData.outcomes[month].ProductSales4}
                        </TableCell>
                        <TableCell align="right">
                          {storeData.outcomes[month].ProductStock4}
                        </TableCell>
                        <TableCell align="right">
                          {formatNumber(
                            storeData.outcomes[month].ProductSales4 *
                            products[storeData.outcomes[month].Product4].RRP *
                            storeData.decisions[month].ProductPrice4,
                            true
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
                <Paper style={{ paddingTop: "1px", marginTop: "10px" }}>
                  <Box display="flex" flexDirection="row">
                    <Typography variant="h6" style={{ margin: "10px" }}>
                      Statement of Financial Position as at 30th {months[month]}
                    </Typography>
                    <Button
                      size="small"
                      startIcon={<ExportIcon />}
                      style={{
                        marginLeft: "auto",
                        marginRight: "16px",
                        flexGrow: "0",
                      }}
                      onClick={() => {
                        downloadCSV(
                          arrayOfArraysToCSV([
                            [
                              "Bank",
                              "Inventory",
                              "Bond",
                              "Fixtures and fittings",
                              "TOTAL ASSETS",
                              "Loan",
                              "NET ASSETS",
                            ],
                            [
                              storeData.outcomes[month].Bank,
                              storeData.outcomes[month].ProductStock1 *
                              products[storeData.outcomes[month].Product1]
                                .Cost_per_item +
                              storeData.outcomes[month].ProductStock2 *
                              products[storeData.outcomes[month].Product2]
                                .Cost_per_item +
                              storeData.outcomes[month].ProductStock3 *
                              products[storeData.outcomes[month].Product3]
                                .Cost_per_item +
                              storeData.outcomes[month].ProductStock4 *
                              products[storeData.outcomes[month].Product4]
                                .Cost_per_item,
                              storeData.location.Bond,
                              storeData.location.Cost_of_Fittings,
                              storeData.outcomes[month].Bank +
                              storeData.outcomes[month].ProductStock1 *
                              products[storeData.outcomes[month].Product1]
                                .Cost_per_item +
                              storeData.outcomes[month].ProductStock2 *
                              products[storeData.outcomes[month].Product2]
                                .Cost_per_item +
                              storeData.outcomes[month].ProductStock3 *
                              products[storeData.outcomes[month].Product3]
                                .Cost_per_item +
                              storeData.outcomes[month].ProductStock4 *
                              products[storeData.outcomes[month].Product4]
                                .Cost_per_item +
                              storeData.location.Bond +
                              storeData.location.Cost_of_Fittings,
                              storeData.outcomes[month].Loan,
                              storeData.outcomes[month].Bank +
                              storeData.outcomes[month].ProductStock1 *
                              products[storeData.outcomes[month].Product1]
                                .Cost_per_item +
                              storeData.outcomes[month].ProductStock2 *
                              products[storeData.outcomes[month].Product2]
                                .Cost_per_item +
                              storeData.outcomes[month].ProductStock3 *
                              products[storeData.outcomes[month].Product3]
                                .Cost_per_item +
                              storeData.outcomes[month].ProductStock4 *
                              products[storeData.outcomes[month].Product4]
                                .Cost_per_item +
                              storeData.location.Bond +
                              storeData.location.Cost_of_Fittings -
                              storeData.outcomes[month].Loan,
                            ],
                          ]),
                          `Statement of Financial Position_${storeData.location.Name}_${months[month]}`
                        );
                      }}
                    >
                      Export
                    </Button>
                  </Box>
                  <Divider />
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell>Bank</TableCell>
                        <TableCell align="right">
                          {formatNumber(storeData.outcomes[month].Bank, true)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Inventory</TableCell>
                        <TableCell align="right">
                          {formatNumber(
                            storeData.outcomes[month].ProductStock1 *
                            products[storeData.outcomes[month].Product1]
                              .Cost_per_item +
                            storeData.outcomes[month].ProductStock2 *
                            products[storeData.outcomes[month].Product2]
                              .Cost_per_item +
                            storeData.outcomes[month].ProductStock3 *
                            products[storeData.outcomes[month].Product3]
                              .Cost_per_item +
                            storeData.outcomes[month].ProductStock4 *
                            products[storeData.outcomes[month].Product4]
                              .Cost_per_item,
                            true
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Bond</TableCell>
                        <TableCell align="right">
                          {formatNumber(storeData.location.Bond, true)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Fixtures and fittings</TableCell>
                        <TableCell align="right">
                          {formatNumber(
                            storeData.location.Cost_of_Fittings,
                            true
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="button"
                            display="block"
                            gutterBottom
                          >
                            Total assets
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          {formatNumber(
                            storeData.outcomes[month].Bank +
                            storeData.outcomes[month].ProductStock1 *
                            products[storeData.outcomes[month].Product1]
                              .Cost_per_item +
                            storeData.outcomes[month].ProductStock2 *
                            products[storeData.outcomes[month].Product2]
                              .Cost_per_item +
                            storeData.outcomes[month].ProductStock3 *
                            products[storeData.outcomes[month].Product3]
                              .Cost_per_item +
                            storeData.outcomes[month].ProductStock4 *
                            products[storeData.outcomes[month].Product4]
                              .Cost_per_item +
                            storeData.location.Bond +
                            storeData.location.Cost_of_Fittings,
                            true
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Loan</TableCell>
                        <TableCell align="right">
                          {formatNumber(
                            -1 * storeData.outcomes[month].Loan,
                            true
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="button"
                            display="block"
                            gutterBottom
                          >
                            Net assets
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          {formatNumber(
                            storeData.outcomes[month].Bank +
                            storeData.outcomes[month].ProductStock1 *
                            products[storeData.outcomes[month].Product1]
                              .Cost_per_item +
                            storeData.outcomes[month].ProductStock2 *
                            products[storeData.outcomes[month].Product2]
                              .Cost_per_item +
                            storeData.outcomes[month].ProductStock3 *
                            products[storeData.outcomes[month].Product3]
                              .Cost_per_item +
                            storeData.outcomes[month].ProductStock4 *
                            products[storeData.outcomes[month].Product4]
                              .Cost_per_item +
                            storeData.location.Bond +
                            storeData.location.Cost_of_Fittings -
                            storeData.outcomes[month].Loan,
                            true
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
              <Grid item sx={12} lg={4}>
                <Paper style={{ paddingTop: "1px", height: "100%" }}>
                  <Box display="flex" flexDirection="row">
                    <Typography variant="h6" style={{ margin: "10px" }}>
                      Statement of Financial Performance
                    </Typography>
                    <Button
                      size="small"
                      startIcon={<ExportIcon />}
                      style={{
                        marginLeft: "auto",
                        marginRight: "16px",
                        flexGrow: "0",
                      }}
                      onClick={() => {
                        downloadCSV(
                          arrayOfArraysToCSV([
                            ["Cost", "Note", "Amount"],
                            [
                              "Revenue",
                              "",
                              storeData.breakdowns[month].revenue,
                            ],
                            [
                              "Purchases",
                              "",
                              storeData.breakdowns[month].purchases,
                            ],
                            ["COGS", "", storeData.breakdowns[month].cogs],
                            [
                              "GROSS PROFIT",
                              "",
                              storeData.breakdowns[month].grossProfit,
                            ],
                            [
                              "Bank interest",
                              "",
                              storeData.breakdowns[month].bankInterest,
                            ],
                            [
                              "Loan interest",
                              "",
                              storeData.breakdowns[month].loanInterest,
                            ],
                            [
                              "Bank modifier",
                              "",
                              storeData.breakdowns[month].drawings,
                            ],
                            [
                              "Advertising",
                              storeData.decisions[month].Promotion,
                              storeData.breakdowns[month].advertising,
                            ],
                            ["Rent", "", storeData.breakdowns[month].rent],
                            [
                              "Office expenses",
                              "",
                              storeData.breakdowns[month].officeExpenses,
                            ],
                            [
                              "Wages",
                              `${storeData.decisions[month].Wage} wage for ${storeData.decisions[month].Staff} staff`,
                            ],
                            [
                              "TOTAL EXPENSES",
                              "",
                              -1 * storeData.breakdowns[month].expenses,
                            ],
                            [
                              "NET PROFIT",
                              "",
                              storeData.breakdowns[month].netProfit,
                            ],
                          ]),
                          `Statement of Financial Performance_${storeData.location.Name}_${months[month]}`
                        );
                      }}
                    >
                      Export
                    </Button>
                  </Box>
                  <Divider />
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Cost</TableCell>
                        <TableCell align="right">Note</TableCell>
                        <TableCell align="right">Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Revenue
                        </TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right">
                          {formatNumber(
                            storeData.breakdowns[month].revenue,
                            true
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Purchases
                        </TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right">
                          {formatNumber(
                            -1 * storeData.breakdowns[month].purchases,
                            true
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography
                            variant="button"
                            display="block"
                            gutterBottom
                          >
                            COGS
                          </Typography>
                        </TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right">
                          {formatNumber(
                            -1 * storeData.breakdowns[month].cogs,
                            true
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography
                            variant="button"
                            display="block"
                            gutterBottom
                          >
                            Gross Profit
                          </Typography>
                        </TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right">
                          {formatNumber(
                            storeData.breakdowns[month].grossProfit,
                            true
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Bank modifier
                        </TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right">
                          {formatNumber(
                            storeData.breakdowns[month].drawings,
                            true
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Advertising
                        </TableCell>
                        <TableCell align="right">
                          {storeData.decisions[month].Promotion}
                        </TableCell>
                        <TableCell align="right">
                          {formatNumber(
                            -1 * storeData.breakdowns[month].advertising,
                            true
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Rent
                        </TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right">
                          {formatNumber(
                            -1 * storeData.breakdowns[month].rent,
                            true
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Office expenses
                        </TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right">
                          {formatNumber(
                            -1 * storeData.breakdowns[month].officeExpenses,
                            true
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Wages
                        </TableCell>
                        <TableCell align="right">
                          {storeData.decisions[month].Wage} wage for{" "}
                          {storeData.decisions[month].Staff} staff
                        </TableCell>
                        <TableCell align="right">
                          {formatNumber(
                            -1 * storeData.breakdowns[month].wages,
                            true
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Transactions
                        </TableCell>
                        <TableCell align="right">
                          Market research
                        </TableCell>
                        <TableCell align="right">
                          {formatNumber(
                            -1 * (storeData.breakdowns[month].services - storeData.breakdowns[month].advertising),
                            true
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography
                            variant="button"
                            display="block"
                            gutterBottom
                          >
                            Total expenses
                          </Typography>
                        </TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right">
                          {formatNumber(
                            -1 * storeData.breakdowns[month].expenses,
                            true
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Bank interest
                        </TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right">
                          {formatNumber(
                            storeData.breakdowns[month].bankInterest,
                            true
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Loan interest
                        </TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right">
                          {formatNumber(
                            -1 * storeData.breakdowns[month].loanInterest,
                            true
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography
                            variant="button"
                            display="block"
                            gutterBottom
                          >
                            Net Profit
                          </Typography>
                        </TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right">
                          {formatNumber(
                            storeData.breakdowns[month].netProfit,
                            true
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid item sx={12} md={6}>
                  <Paper style={{ padding: "24px" }}>
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        <Typography variant="h6">
                          Submit {months[month]} decisions
                        </Typography>
                      </Grid>
                      <Grid item>
                        <div>
                          Employ{" "}
                          <Select
                            value={staff}
                            onChange={(info, value) =>
                              setStaff(value.props.value)
                            }
                            autoWidth
                          >
                            {Array.from(
                              Array(
                                storeData.location.Staff_Capacity + 1
                              ).keys()
                            ).map((value) => (
                              <MenuItem value={value} key={value}>
                                {numbers[value]}
                              </MenuItem>
                            ))}
                          </Select>{" "}
                          staff at{" "}
                          <Select
                            value={wage}
                            onChange={(info, value) =>
                              setWage(value.props.value)
                            }
                            autoWidth
                          >
                            <MenuItem value={"Minimum"}>minimum</MenuItem>
                            <MenuItem value={"Living"}>living</MenuItem>
                          </Select>{" "}
                          wage.
                        </div>
                      </Grid>
                      <Grid item>
                        <div>
                          Repay
                          {/* <TextField
											label="Amount"
											type="number"
											InputLabelProps={{
												shrink: true,
											}}
											style={{ height: '10px' }}
											InputLabelProps={{
												style: {
													height: '7px',
													top: `-22px`,
												},
											}}

											inputProps={{
												style: {
													height: '7px',
													padding: '0 14px',
												},
											}}
										/> */}
                          <FormControl
                            style={{
                              width: "100px",
                              margin: "3px",
                              marginTop: "-5px",
                            }}
                            variant="standard"
                          >
                            <Input
                              id="outlined-adornment-amount"
                              type="number"
                              defaultValue={LoanPrincipalRepayment}
                              required
                              onChange={(event) => {
                                setLoanPrincipalRepayment(
                                  event.target.valueAsNumber
                                );
                              }}
                              startAdornment={
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              }
                              labelWidth={60}
                            />
                          </FormControl>
                          of the remaining
                          {" " +
                            formatNumber(
                              storeData.outcomes[month - 1].Loan,
                              true
                            ) +
                            " "}
                          of your loan.
                        </div>
                      </Grid>
                      <Grid item>
                        <div>
                          Promote my products with{" "}
                          <Select
                            value={promotion}
                            onChange={(info, value) =>
                              setPromotion(value.props.value)
                            }
                          >
                            <MenuItem value="None">no</MenuItem>
                            <MenuItem value="Online">online</MenuItem>
                            <MenuItem value="Radio">radio</MenuItem>
                            <MenuItem value="Newspaper">newspaper</MenuItem>
                          </Select>{" "}
                          advertising.
                        </div>
                      </Grid>
                      {productNumbers.map((i, x) => (
                        <Grid item key={x}>
                          <div>
                            Purchase
                            <FormControl
                              style={{
                                width: "55px",
                                margin: "3px",
                                marginTop: "0px",
                              }}
                              variant="standard"
                            >
                              <Input
                                id="outlined-adornment-amount"
                                type="number"
                                defaultValue={ProductPurchases[i]}
                                onChange={(event) => {
                                  setProductPurchases((prev) => ({
                                    ...prev,
                                    [i]: event.target.valueAsNumber,
                                  }));
                                }}
                                labelWidth={70}
                              />
                            </FormControl>
                            of{" "}
                            <Select
                              value={Product[i]}
                              onChange={(info, value) => {
                                setProduct((prev) => ({
                                  ...prev,
                                  [i]: value.props.value,
                                }));
                              }}
                            >
                              {Object.keys(products)
                                .filter(
                                  (a) =>
                                    Product[i] === a ||
                                    a === "None" ||
                                    !Object.values(Product).includes(a)
                                )
                                .map((product) => (
                                  <MenuItem value={product} key={product}>
                                    {product}
                                  </MenuItem>
                                ))}
                            </Select>
                            , resulting in{" "}
                            {productStocks[Product[i]]
                              ? productStocks[Product[i]] + ProductPurchases[i]
                              : ProductPurchases[i]}{" "}
                            units available for sale, and sell them at a{" "}
                            <Select
                              value={ProductPrice[i]}
                              onChange={(info, value) => {
                                setProductPrice((prev) => ({
                                  ...prev,
                                  [i]: value.props.value,
                                }));
                              }}
                            >
                              <MenuItem value={0.65}>very low</MenuItem>
                              <MenuItem value={0.85}>low</MenuItem>
                              <MenuItem value={1}>normal</MenuItem>
                              <MenuItem value={1.15}>high</MenuItem>
                              <MenuItem value={1.3}>very high</MenuItem>
                            </Select>
                            price.
                          </div>
                        </Grid>
                      ))}

                      <Grid item>
                        Currently stocking {totalStock} out of the total storage
                        capacity of {storageCapacity}
                        {otherStores.length > 0 &&
                          ` across ${otherStores.length + 1} stores`}
                        .
                      </Grid>

                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={submitDecisions}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item sx={12} md={6}>
                  <RelatedHandbookSections
                    related={[
                      {
                        chapter: "Markets",
                        section: "Markets",
                      },
                      {
                        chapter: "Marketing",
                        section:
                          "Promotion - how the business communicates with their target market",
                      },
                      {
                        chapter: "Marketing",
                        section: "Product - what you’re selling",
                      },
                      {
                        chapter: "Marketing",
                        section: "Terms explained - products",
                      },
                      {
                        chapter: "Marketing",
                        section:
                          "Price - what the customer pays to get the product",
                      },
                      {
                        chapter: "Marketing",
                        section: "Terms explained - price",
                      },
                      {
                        chapter: "Marketing",
                        section: "Staffing",
                      },
                    ]}
                  />
                </Grid>
              </Grid>
              <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
              >
                <Alert severity={severity} onClose={handleClose}>
                  {error}
                </Alert>
              </Snackbar>
            </>
          )}
        </div>
      </div>
    </Fade>
  );
}

export default function TeamDetails(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { dataUpdated } = props;
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { teamData } = props;

  const storeCols = [
    { field: "number", headerName: "Store #", flex: 6 },
    {
      field: "netProfit",
      headerName: "Net Profit",
      flex: 8,
      renderCell: (params) =>
        params.value === "N/A" ? "N/A" : formatNumber(params.value, true),
    },
    {
      field: "bank",
      headerName: "Bank Balance",
      flex: 9,
      renderCell: (params) => formatNumber(params.value, true),
    },
    {
      field: "remainingLoan",
      headerName: "Remaining Loan",
      flex: 10,
      renderCell: (params) => formatNumber(params.value, true),
    },
    { field: "location", headerName: "Location", flex: 12 },
    {
      field: "submitted",
      headerName: "Decisions submitted?",
      flex: 12,
      renderCell: (params) => (params.value ? <CheckIcon /> : <CloseIcon />),
    },
  ];

  let storeRows = [];

  storeRows = Object.keys(teamData.stores).map((key, i) => {
    const store = teamData.stores[key];
    const lastMonth = Math.max(...Object.keys(store.outcomes));
    let modifier = store.modifiers[lastMonth + 1];
    if (modifier === undefined) {
      modifier = { Sales: 1, Bank: 0 };
    }

    return {
      id: i,
      number: key,
      location: store.location.Name,
      netProfit:
        lastMonth === Math.min(...Object.keys(store.outcomes))
          ? "N/A"
          : store.breakdowns[lastMonth].netProfit,
      bank: store.outcomes[lastMonth].Bank + modifier.Bank,
      remainingLoan: store.outcomes[lastMonth].Loan,
      submitted: store.decisions[lastMonth + 1],
    };
  });
  console.log("rows 1", storeRows);

  return (
    <RouterSwitch>
      <Route exact path={path}>
        <Fade in timeout={500}>
          <div className={classes.classPage}>
            <Typography variant="h4" style={{ marginBottom: "24px" }}>
              Your stores
            </Typography>

            <Paper className={classes.dataGridWrapper}>
              <DataGrid
                className={classes.dataGrid}
                // rowHeight="35"
                rows={storeRows}
                columns={storeCols}
                pageSize={20}
                // components={{
                // 	Toolbar: CustomToolbar,
                // }}
                onRowClick={(params) =>
                  history.push(url + "/" + params.row.number)
                }
                classes={{ row: classes.clickable }}
                style={{
                  color: theme.palette.type === "dark" ? "white" : "black",
                }}
              />
            </Paper>
          </div>
        </Fade>
      </Route>
      <Route path={path + "/:storeNumber"}>
        {/* <Fade in timeout={500}> */}
        <StoreDetails teamData={teamData} />
        {/* </Fade> */}
      </Route>
    </RouterSwitch>
  );
}
