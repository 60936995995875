export default function formatNumber(num, currency) {
	let options = {
		style: "currency",
		currency: "NZD"
	};
	if (!currency) {
		options = {
			maximumFractionDigits: 1,
			notation: "compact",
			compactDisplay: "short"
		};
	}

	// This is because for some reason -0 exists, and messes up formatting
	num = num || 0

	const isNegative = parseInt(num) < 0

	const formatted = new Intl.NumberFormat("en-NZ", options).format(num * (isNegative ? -1 : 1))

	return isNegative ? "(" + formatted + ")" : formatted;
}
